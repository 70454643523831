import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store';
import FormPanel from 'components/FormPanel';
import Input from 'components/Input';
import Select from 'components/Select';
import Button from 'components/Button';
import RadioGroup from 'components/RadioGroup';
import PhoneInput from 'components/FormCells/PhoneInput';
import DatePicker from 'components/DatePicker';
import BackdropSpinner from 'components/BackdropSpinner';
import { useFormContext, Controller } from 'react-hook-form';
import ErrorHandler from 'utils/ErrorHandler';
import {
  getCountryState, getEmploymentFields, updateCountry, getAccountHolderDetailForm
} from 'api/v1/account';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useCreateJointAccountFlow } from 'store/context/hooks';
import {
  Countries, CreateJointAccountSteps, JOINT_ACCOUNT_STEPS
} from 'constant/createAccount';
import PopUp from 'components/PopUp';
import CircularProgress from '@mui/material/CircularProgress';
import { getFieldWarningMessage, checkFieldIsWarning, checkFieldIsCorrecting } from 'containers/CreateAccountForm/utils';
import { isArray } from 'lodash';
import langData from 'i18n/langs.json';
import { ResponseFieldsProps, OptionsProps } from 'type/form';
import { StyledLoadingWrap } from 'containers/CreateJointAccountForm/components/style';
import { appDayjs } from 'utils/appDayjs.util';
import {
  StyledHalfContentWrap, StyledInputWrap, StyledContentWrap, StyledRadioGroupWrapper, StyledPrimaryAccountHolderWrap
} from './style';

interface RenderContentProps {
  fields: ResponseFieldsProps[];
  len: number | null;
}

interface SelectHandlerProps {
  onChange: ((...event: unknown[]) => void) | (() => void);
  prev: string;
  next: string;
}

const PrimaryAccountHolder = (): JSX.Element => {
  const { t, i18n } = useTranslation('registrationForm');
  const {
    control, formState: { errors }, setValue, getValues, resetField
  } = useFormContext();
  const {
    rejectWarningFields, rejectStepStatus, sanctionedCountriesInfo,
    updateFlow, updateRejectStepWarningStatus, updateSteps
  } = useCreateJointAccountFlow();
  const [isPrimaryAccountHolderLoading, setIsPrimaryAccountHolderLoading] = useState(true);
  const [isEmploymentLoading, setIsEmploymentLoading] = useState(true);
  const [isBackdropSpinnerOpen, setIsBackdropSpinnerOpen] = useState(false);
  const [primaryAccountHolderForm, setPrimaryAccountHolderForm] = useState<ResponseFieldsProps[] | null>(null);
  const [employment, setEmployment] = useState<ResponseFieldsProps[]>([]);
  const [livingState, setLivingState] = useState<OptionsProps[] | null>(null);
  const [phoneValue, setPhoneValue] = useState('');
  const [openSanctionedCountriesModal, setOpenSanctionedCountriesModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const employmentFieldsLength = employment && Math.round(employment.length / 2);
  const countryValue = getValues('country');
  const isAUS = countryValue === Countries.AUS;
  const currentStep = CreateJointAccountSteps.JointPrimaryAccountHolder;

  const updateCountryState = (country: string): void => {
    const noStateOption = [{ value: 'N/A', label: 'N/A' }];
    setLivingState(noStateOption);
    getCountryState(country).then((res) => {
      if (res.status === 200) {
        const resState = res.data.options.length === 0 ? noStateOption : res.data.options;
        setLivingState(resState);
      }
    });
  };

  const updateEmployment = (country: string): void => {
    setIsEmploymentLoading(true);
    getEmploymentFields(country).then((res) => {
      if (res) {
        setEmployment(res?.data || []);
      }
    }).finally(() => setIsEmploymentLoading(false));
  };

  const selectHandler = ({ onChange, prev, next }: SelectHandlerProps): void => {
    if (prev === next) return;
    const isSanctionedCountry = sanctionedCountriesInfo.countries.includes(next);
    if (isSanctionedCountry) {
      setOpenSanctionedCountriesModal(true);
      return;
    }

    if (prev === Countries.AUS || next === Countries.AUS) {
      setIsBackdropSpinnerOpen(true);
      if (Object.keys(errors).length > 0) {
        Object.keys(errors).forEach((fieldKey) => {
          resetField(fieldKey);
        });
      }
      ['funding_currency', 'leverage', 'mt_account_type', 'state', 'zip_code'].forEach((el: string) => setValue(el, null));
      onChange(next);
      ErrorHandler(updateCountry({
        accountID: localStorage.getItem('accountID')
          ? localStorage.getItem('accountID') : null,
        form: {
          ...getValues(),
          original_country: prev,
          form_step: CreateJointAccountSteps.JointPrimaryAccountHolder
        }
      }), dispatch).then((res) => {
        if (res && res.status === 200) {
          if (next === Countries.AUS) {
            updateFlow('ASIC');
            updateSteps(JOINT_ACCOUNT_STEPS.ASIC);
          } else {
            updateFlow('VFSC');
            updateSteps(JOINT_ACCOUNT_STEPS.VFSC);
          }
          Object.keys(res.data).forEach((eachKey) => {
            if (['_method'].some((el: string) => el !== eachKey)) {
              setValue(eachKey, res.data[eachKey]);
            }
            if (eachKey === 'terms_and_conditions') {
              const tncObj = JSON.parse(String(res.data.terms_and_conditions));
              if (tncObj !== undefined && tncObj !== null) {
                Object.keys(tncObj).forEach((eachTerm) => {
                  setValue(eachTerm, tncObj[eachTerm]);
                });
              }
            }
          });
          updateEmployment(next);
          updateCountryState(next);
        }
      }).catch(() => {
        onChange(prev);
      }).finally(() => setIsBackdropSpinnerOpen(false));
    } else {
      onChange(next);
      updateEmployment(next);
      updateCountryState(next);
    }
  };

  const inputChangeHandler = (id: string, index = 0): void => {
    if (searchParams.get('status') !== 'Reject') return;
    if (rejectWarningFields[currentStep] && isArray(rejectWarningFields[currentStep]) && rejectWarningFields[currentStep][index][id]) {
      if (getValues(id) === rejectWarningFields[currentStep][index][id].warning_value) {
        if (!rejectStepStatus[currentStep].isWarning) {
          updateRejectStepWarningStatus(currentStep, true);
        }
      } else {
        for (let j = 0; j < rejectWarningFields[currentStep].length; j += 1) {
          const fieldsArr = Object.keys(rejectWarningFields[currentStep][j]);
          for (let i = 0; i < fieldsArr.length; i += 1) {
            if (rejectWarningFields[currentStep][j][fieldsArr[i]].warning_value === getValues(fieldsArr[i])) {
              if (!rejectStepStatus[currentStep].isWarning) {
                updateRejectStepWarningStatus(currentStep, true);
              }
              return;
            }
          }
        }
        if (rejectStepStatus[currentStep].isWarning) {
          updateRejectStepWarningStatus(currentStep, false);
        }
      }
    }
  };

  useEffect(() => {
    ErrorHandler(getAccountHolderDetailForm(), dispatch).then((data) => {
      if (data.status === 200) {
        setPrimaryAccountHolderForm(data.data);
      }
    });
    updateEmployment(getValues('country'));
    const isSanctionedCountry = sanctionedCountriesInfo.countries.includes(countryValue);

    if (isSanctionedCountry) {
      setValue('country', '');
    }

    if (!isSanctionedCountry) {
      updateCountryState(countryValue);
      setIsPrimaryAccountHolderLoading(false);
    } else setIsPrimaryAccountHolderLoading(false);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderInputCell = (item: ResponseFieldsProps, index: number): JSX.Element => {
    switch (item.type) {
      case 'input':
        return (
          <StyledInputWrap
            key={item.id}
            isHalfWidth={['zip_code', 'unit', 'street_number'].includes(item.id)}
            hasMargin={['unit'].includes(item.id)}
          >
            <Controller
              control={control}
              name={item.id}
              {...item.rules}
              rules={{
                ...item.rules,
                pattern: item?.rules?.pattern && {
                  value: new RegExp(item.rules?.pattern.value),
                  message: item.rules?.pattern.message
                }
              }}
              render={({
                field: {
                  onChange,
                  value
                }
              }) => (
                <Input
                  label={item.name}
                  height="48px"
                  placeholder={item.placeHolder}
                  onChange={(val) => {
                    onChange(val);
                    inputChangeHandler(item.id);
                  }}
                  value={value || ''}
                  errorMsg={errors[item.id] && (errors[item.id]?.message as unknown as string)}
                  warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, item.id)}
                  disabled={checkFieldIsWarning(rejectWarningFields, currentStep, item.id)}
                  markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, item.id, value)}
                  id={item.id}
                />
              )}
            />
          </StyledInputWrap>
        );

      case 'select':
        return (
          <StyledInputWrap
            key={item.id}
            isHalfWidth={['state'].includes(item.id)}
            hasMargin={['state'].includes(item.id)}
          >
            <Controller
              control={control}
              name={item.id}
              {...item.rules}
              rules={{ required: item.rules?.required?.value }}
              render={({
                field: {
                  onChange,
                  value
                }
              }) => (
                <Select
                  label={item.name}
                  options={item.id === 'state' && livingState ? livingState : item.options}
                  inputHeight="48px"
                  onChange={(val) => {
                    if (item.id === 'country') {
                      selectHandler({ onChange, next: val, prev: value });
                    } else {
                      onChange(val);
                    }
                    inputChangeHandler(item.id);
                  }}
                  menuPlacement={item.id === 'state' || item.id === 'nationality' ? 'top' : 'bottom'}
                  currentValue={value}
                  defaultValue={value}
                  placeholder={item.placeHolder}
                  errorMsg={errors[item.id] && 'Error'}
                  warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, item.id)}
                  disabled={checkFieldIsWarning(rejectWarningFields, currentStep, item.id)}
                  markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, item.id, value)}
                  id={item.id}
                />
              )}
            />
          </StyledInputWrap>
        );
      case 'phone':
        return (
          <StyledInputWrap key={item.id}>
            <Controller
              control={control}
              name={item.id}
              {...item.rules}
              rules={{ required: item.rules?.required?.value }}
              render={({
                field: {
                  onChange, value
                }
              }) => (
                <PhoneInput
                  id={item.id}
                  label={item.name}
                  height="48px"
                  onChange={(phoneVal: string, info: { [key: string]: string }) => {
                    onChange(phoneVal);
                    if (phoneVal === info.dialCode) {
                      setValue('mobile', null);
                      setPhoneValue(info.dialCode);
                    }
                    inputChangeHandler(item.id);
                  }}
                  defaultValue={value || phoneValue}
                  warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, item.id)}
                  disabled={checkFieldIsWarning(rejectWarningFields, currentStep, item.id)}
                  markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, item.id, value)}
                  localization={langData[i18n.language as keyof typeof langData]}
                />
              )}
            />
          </StyledInputWrap>
        );

      case 'date':
        return (
          <StyledInputWrap key={item.id}>
            <Controller
              control={control}
              name={item.id}
              {...item.rules}
              rules={{ required: item.rules?.required?.value }}
              render={({
                field: {
                  onChange,
                  value
                }
              }) => (
                <DatePicker
                  disableFuture
                  id={item.id}
                  label={item.name}
                  onChange={(val) => {
                    onChange(val);
                    inputChangeHandler(item.id);
                  }}
                  defaultValue={value}
                  maxDate={appDayjs().subtract(18, 'year')}
                  minDate={isAUS ? appDayjs().subtract(85, 'year') : undefined}
                  warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, item.id)}
                  disabled={checkFieldIsWarning(rejectWarningFields, currentStep, item.id)}
                  markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, item.id, value)}
                />
              )}
            />
          </StyledInputWrap>
        );

      case 'radioButton':
        return (
          <StyledInputWrap key={item.id}>
            <Controller
              control={control}
              name={item.id}
              rules={{ required: item?.rules?.required?.value }}
              render={({
                field: {
                  onChange, value
                }
              }) => (
                <StyledRadioGroupWrapper>
                  <RadioGroup
                    options={item.options}
                    id={item.id}
                    groupName={item.id}
                    label={item.name}
                    optionType="button"
                    onChange={onChange}
                    value={value}
                    disabled={checkFieldIsWarning(rejectWarningFields, currentStep, item.id)}
                  />
                  <div className="optionBtnErr">
                    {errors[item.id] && 'Error'}
                  </div>
                </StyledRadioGroupWrapper>
              )}
            />
          </StyledInputWrap>
        );

      default:
        return (
          <StyledInputWrap key={item.id}>
            <Input
              id={item.id}
              label={item.name}
              height="48px"
              placeholder={item.placeHolder}
              warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, item.id)}
              disabled={checkFieldIsWarning(rejectWarningFields, currentStep, item.id)}
            />
          </StyledInputWrap>
        );
    }
  };

  const renderContent = ({ fields, len }: RenderContentProps): JSX.Element => (
    <>
      <StyledHalfContentWrap>
        {fields.map((item: ResponseFieldsProps, index: number) => {
          if (len && len > index && item.id !== 'email') {
            return renderInputCell(item, index);
          }
          return null;
        })}
      </StyledHalfContentWrap>
      <StyledHalfContentWrap>
        {fields.map((item: ResponseFieldsProps, index: number) => {
          if (len && len <= index) {
            return renderInputCell(item, index);
          }
          return null;
        })}
      </StyledHalfContentWrap>
    </>
  );

  return (
    <StyledPrimaryAccountHolderWrap>
      <FormPanel title={t('primaryAccountHolder')} id="primaryAccountHolder">
        <StyledContentWrap className="StyledContentWrap flx-row" gap={24}>
          {primaryAccountHolderForm && !isPrimaryAccountHolderLoading ? (
            renderContent({ fields: primaryAccountHolderForm, len: 8 })
          ) : (
            <StyledLoadingWrap>
              <CircularProgress />
            </StyledLoadingWrap>
          )}
        </StyledContentWrap>
        <PopUp
          content={sanctionedCountriesInfo.message}
          openModal={openSanctionedCountriesModal}
        >
          <Button aria-label="Pop Up OK" onClick={() => setOpenSanctionedCountriesModal(false)}>
            {t('ok')}
          </Button>
        </PopUp>
      </FormPanel>
      <FormPanel title={t('employment')} id="Employment">
        <StyledContentWrap className="StyledContentWrap flx-row" gap={24}>
          {employment && !isEmploymentLoading ? (
            renderContent({ fields: employment, len: employmentFieldsLength })
          ) : (
            <StyledLoadingWrap>
              <CircularProgress />
            </StyledLoadingWrap>
          )}
        </StyledContentWrap>
      </FormPanel>
      <BackdropSpinner open={isBackdropSpinnerOpen} />
    </StyledPrimaryAccountHolderWrap>
  );
};

export default PrimaryAccountHolder;
