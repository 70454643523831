/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState, useRef } from 'react';
import { useAppDispatch } from 'store';
import FormPanel from 'components/FormPanel';
import Input from 'components/Input';
import Select from 'components/Select';
import Button from 'components/Button';
import PhoneInput from 'components/FormCells/PhoneInput';
import DatePicker from 'components/DatePicker';
import { useFormContext, Controller } from 'react-hook-form';
import ErrorHandler from 'utils/ErrorHandler';
import BackdropSpinner from 'components/BackdropSpinner';
import {
  getPersonalDetailForm, getCountryState, updateCountry, getVeriffData,
  getAccountByID
} from 'api/v1/account';
import { getCountryList } from 'api/v1/user';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useCreateAccountFlow } from 'store/context/hooks';
import { Countries, PERSONAL_ACCOUNT_STEPS, CreateAccountSteps } from 'constant/createAccount';
import CustomPopup from 'components/CustomPopup';
import { backStep, backTwoSteps } from 'containers/CreateAccountForm/slices';
import CircularProgress from '@mui/material/CircularProgress';
import { getFieldWarningMessage, checkFieldIsWarning, checkFieldIsCorrecting } from 'containers/CreateAccountForm/utils';
import { isArray, isEmpty } from 'lodash';
import langData from 'i18n/langs.json';
import { appDayjs } from 'utils/appDayjs.util';
import KYC from 'utils/kyc.util';
import {
  StyledHalfContentWrap,
  StyledInputWrap,
  StyledContentWrap,
  StyledPopupWrap,
  StyledPopupContent,
  StyledVeriffLoadingWrap,
  StyledVeriffLoadingContent
} from './style';
import { StyledLoadingWrap } from '../style';

// enum PopUpType {
//   Initial = 0,
//   VerifyIdentity,
//   Retry = 2
// }

interface InputInfoProps {
  type: string;
  name: string;
  id: string;
  placeHolder: string;
  options?: OptionsProps[];
  rules?: {
    pattern?: { value: string, message: string };
    maxLength?: { value: number, message: string };
    required?: { value: boolean, message: string }
  };
  errMsg?: {
    required?: string;
    pattern?: string;
  };
}

interface OptionsProps {
  value: string;
  label: string;
}

enum WorkFlowType {
  INITIATED = 'INITIATED',
  ACQUIRED = 'ACQUIRED',
  PROCESSED = 'PROCESSED',
  SESSION_EXPIRED = 'ACQUIRED',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED'
}

enum Regulation {
  ASIC = 'ASIC',
  SVG = 'SVG FSA'
}

interface veriffResData {
  data: {
    workflow_status?: WorkFlowType;
    user_details: {
      dob: string;
      first_name: string;
      gender: string;
      last_name: string;
      nationality: string;
      state: string;
      address: {
        [key: string]: string;
      };
      document: {
        identification_number: string;
        subType: string;
        type: string;
      }
    };
    decision: string;
    risk: number | string;
    attempts: number;
  };
}

interface SelectHandlerProps {
  onChange: ((...event: unknown[]) => void) | (() => void);
  prev: string;
  next: string;
}

const PersonDetails = (): JSX.Element => {
  const { t, i18n } = useTranslation('registrationForm');
  const {
    control, formState: { errors }, setValue, getValues, resetField, setError, watch
  } = useFormContext();
  const {
    method, flow, rejectWarningFields, rejectStepStatus, sanctionedCountriesInfo,
    updateFlow, updateSteps, updatePOACertification, updateShowTip, updateMethod,
    updateRejectStepWarningStatus, updateInitCountry
  } = useCreateAccountFlow();
  const [isLoading, setIsLoading] = useState(true);
  const [personalForm, setPersonalForm] = useState<InputInfoProps[] | null>(null);
  const [livingState, setLivingState] = useState<OptionsProps[] | null>(null);
  const [phoneValue, setPhoneValue] = useState('');
  const [preCountry, setPreCountry] = useState('');
  const [curCountry, setCurCountry] = useState('');
  const [regulation, setRegulation] = useState('');
  const [veriffFetch, setVeriffFetch] = useState(false);
  // const [jumioStart, setJumioStart] = useState(false);
  const [changeRegulation, setChangeRegulation] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openSanctionedCountriesModal, setOpenSanctionedCountriesModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const originCountryRef = useRef('');
  const isVeriffAddress = useRef<boolean>(false);
  const [searchParams] = useSearchParams();

  const num = personalForm && personalForm.length / 2 - 1;
  const countryValue = getValues('country');
  const isAUS = countryValue === Countries.AUS;
  const currentStep = CreateAccountSteps.PersonalInformation;
  const selectHandler = ({ onChange, prev, next }: SelectHandlerProps): void => {
    if (prev === next) return;
    if ([prev, next].includes(Countries.AUS)) setValue('dob', null);
    setRegulation(next === Countries.AUS ? Regulation.ASIC : Regulation.SVG);
    const noStateOption = [{ value: 'N/A', label: 'N/A' }];
    const isSanctionedCountry = sanctionedCountriesInfo.countries.includes(next);
    if (isSanctionedCountry) {
      // If Manually select the country
      // only error message appears, the country need to be modified
      setError('country', { type: 'select', message: sanctionedCountriesInfo.message });
      setValue('country', next);
      setLivingState([]);
      return;
    }

    // The popup displays the full names of 2 countries
    if (personalForm) {
      const countryList = personalForm.find((x) => x.id === 'nationality')?.options || [];
      setCurCountry(countryList.find((x: { value: string; }) => x.value === next)?.label || next);
      setPreCountry(countryList.find((x: { value: string; }) => x.value === prev)?.label || next);
    } else {
      getCountryList().then((res) => {
        if (res && res.status === 200) {
        // The full names of 2 countries are displayed on the popup
          setCurCountry(res?.data?.options?.find((x: { value: string; }) => x.value === next)?.label || next);
          setPreCountry(res?.data?.options?.find((
            x: { value: string; }
          ) => x.value === prev).label || prev);
        }
      });
    }

    originCountryRef.current = prev;
    if ((flow === 'ASIC' && next !== Countries.AUS)
      || (flow === 'VFSC' && next === Countries.AUS)) {
      setOpenModal(true);
      onChange(next);
      return;
    }
    onChange(next);
    setValue('state', null);
    setLivingState(noStateOption);
    getCountryState(next).then((res) => {
      if (res.status === 200) {
        if (next === Countries.AUS) {
          updateFlow('ASIC');
          if (method === 'AI') {
            if (isVeriffAddress.current) updateSteps(PERSONAL_ACCOUNT_STEPS.AIASIC);
            else {
              updateSteps(PERSONAL_ACCOUNT_STEPS.AIASICPOA);
            }
          }
          if (method === 'Traditional') updateSteps(PERSONAL_ACCOUNT_STEPS.TraditionASIC);
        } else {
          updateFlow('VFSC');
          if (method === 'AI') updateSteps(PERSONAL_ACCOUNT_STEPS.AIVFSC);
        }
        const resState = res.data.options.length === 0 ? noStateOption : res.data.options;
        setLivingState(resState);
      }
    });
  };

  // const handleStart = (): void => {
  //   // Go to Jimio
  //   setJumioStart(true);
  //   setPopupType(PopUpType.Initial);
  //   getJumioInfo('/create-account/personal-account', String(localStorage.getItem('accountID'))).then((res) => {
  //     if (res && res.data && res.data.data) {
  //       window.location.replace(res.data.data.herf);
  //     }
  //   })
  //     .catch((e) => {
  //       const { code } = e.response.data;
  //       // LP2-5371 hotfix, when jumio return error 410
  //       if (code === 410) {
  //         setJumioStart(false);
  //       }
  //     });
  // };

  const determineSteps = (
    {
      veriffDecision,
      requirePOA
    }: {
      veriffDecision?: string;
      requirePOA: number;
    }
  ): void => {
    if (veriffDecision === undefined) {
      if (flow === 'ASIC') {
        updateSteps(PERSONAL_ACCOUNT_STEPS.TraditionASIC);
      } else {
        updateSteps(PERSONAL_ACCOUNT_STEPS.NormalVFSC);
      }
    } else if (requirePOA === 1) {
      if (flow === 'ASIC') {
        updateSteps(PERSONAL_ACCOUNT_STEPS.TraditionASIC);
      } else {
        updateSteps(PERSONAL_ACCOUNT_STEPS.NormalVFSC);
      }
    } else if (flow === 'ASIC') {
      updateSteps(PERSONAL_ACCOUNT_STEPS.AIASIC);
    } else {
      updateSteps(PERSONAL_ACCOUNT_STEPS.AIVFSC);
    }
  };

  const veriffUserFetch = (): void => {
    setIsLoading(false);
    setVeriffFetch(true);
    const accountID = String(localStorage.getItem('accountID'));

    getVeriffData(accountID).then((data: veriffResData) => {
      const {
        user_details: userDetails,
        decision
      } = data.data;
      const isSanctionedCountry = sanctionedCountriesInfo.countries.includes(userDetails?.address?.country);

      ErrorHandler(getAccountByID(accountID), dispatch).then((res) => {
        if (res.status === 200) {
          determineSteps({
            veriffDecision: decision,
            requirePOA: res.data.require_poa
          });
        }
      }).finally(() => {
        setVeriffFetch(false);
      });

      // veriff Success
      if (decision === 'approved') {
        Object.keys(userDetails).forEach((item) => {
          if (item === 'dob' && KYC.isUserAgeRangeValid(userDetails[item], isAUS)) {
            setValue(item, appDayjs(userDetails[item]).format('YYYY-MM-DD'));
          }
          if ((item === 'first_name' || item === 'gender' || item === 'last_name') && userDetails[item]) {
            setValue(item, userDetails[item]);
          }
          if (item === 'nationality' && userDetails[item]) {
            setValue(item, userDetails[item]);
          }
          if (item === 'document' && userDetails?.document?.identification_number) {
            setValue('"photo_id_number', userDetails?.document?.identification_number);
          }
        });
        // residential_address, line2, zip_code, state, city, formattedAddress, country
        Object.keys(userDetails.address).forEach((item) => {
          if (userDetails.address[item]) {
            if (item === 'country') {
              if (!isSanctionedCountry && userDetails?.address?.country) {
                selectHandler({
                  onChange: () => setValue('country', userDetails.address.country),
                  next: userDetails.address[item],
                  prev: countryValue
                });
              }

              // If the data returned by veriff is a sanctioned country
              // only a popup will appear, the country will not be modified
              // and no error message will appear.
              if (isSanctionedCountry && userDetails?.address?.country) {
                getCountryList().then((res) => {
                  if (res && res.status === 200) {
                    // The full names of 2 countries are displayed on the popup
                    setPreCountry(res?.data?.options?.find((x: { value: string; }) => x.value === countryValue)?.label || countryValue);
                    setCurCountry(res?.data?.options?.find((
                      x: { value: string; }
                    ) => x.value === userDetails?.address?.country)?.label || userDetails?.address?.country);
                  }
                });

                setOpenSanctionedCountriesModal(true);
              }
            } else if (item === 'state' && !isSanctionedCountry) {
              // If the state(veriff) uses an abbreviation, the field is displayed as empty, but the next button can be clicked
              const isMatchStateList = !isEmpty(livingState?.filter((x) => x.value === userDetails?.address?.state));
              if (isMatchStateList) {
                setValue(item, userDetails.address[item]);
              }
            } else if (!isSanctionedCountry) {
              setValue(item, userDetails.address[item]);
            }
          }
        });

        if (countryValue) {
          getCountryState(countryValue).then((res) => {
            if (res.status === 200) {
              const noStateOption = [{ value: 'N/A', label: 'N/A' }];
              const resState = res.data.options.length === 0 ? noStateOption : res.data.options;
              setLivingState(resState);
            }
          }).then(() => {
            if (userDetails.state) setValue('state', userDetails.state);
          });
        }

        if (flow === 'ASIC') {
          if (userDetails?.address.residential_address && !isSanctionedCountry) {
            updateSteps(PERSONAL_ACCOUNT_STEPS.AIASIC);
            isVeriffAddress.current = true;
          } else {
            updateSteps(PERSONAL_ACCOUNT_STEPS.AIASICPOA);
          }
        }
        localStorage.setItem('hasVeriffAddress', userDetails?.address.residential_address);
        setIsLoading(false);
      } else if (decision !== '' && decision !== 'approved') {
        // Veriff Fail
        if (countryValue) {
          getCountryState(countryValue).then((res) => {
            if (res.status === 200) {
              const noStateOption = [{ value: 'N/A', label: 'N/A' }];
              const resState = res.data.options.length === 0 ? noStateOption : res.data.options;
              setLivingState(resState);
            }
          });
        }
        setIsLoading(false);
        // when ASIC fail
        if (flow === 'ASIC') {
          updateSteps(PERSONAL_ACCOUNT_STEPS.AIASICPOA);
        } else {
          updateSteps(PERSONAL_ACCOUNT_STEPS.AIVFSC);
        }
      }
    }).catch((err) => {
      setIsLoading(false);
      console.error(err);
    });
  };

  const inputChangeHandler = (id: string, index: number): void => {
    if (searchParams.get('status') !== 'Reject') return;
    if (rejectWarningFields[currentStep] && isArray(rejectWarningFields[currentStep]) && rejectWarningFields[currentStep][index][id]) {
      if (getValues(id) === rejectWarningFields[currentStep][index][id].warning_value) {
        if (!rejectStepStatus[currentStep].isWarning) {
          updateRejectStepWarningStatus(currentStep, true);
        }
      } else {
        for (let j = 0; j < rejectWarningFields[currentStep].length; j += 1) {
          const fieldsArr = Object.keys(rejectWarningFields[currentStep][j]);
          for (let i = 0; i < fieldsArr.length; i += 1) {
            if (rejectWarningFields[currentStep][j][fieldsArr[i]].warning_value === getValues(fieldsArr[i])) {
              if (!rejectStepStatus[currentStep].isWarning) {
                updateRejectStepWarningStatus(currentStep, true);
              }
              return;
            }
          }
        }
        if (rejectStepStatus[currentStep].isWarning) {
          updateRejectStepWarningStatus(currentStep, false);
        }
      }
    }
  };

  useEffect(() => {
    ErrorHandler(getPersonalDetailForm(), dispatch).then((data) => {
      if (data.status === 200) {
        setPersonalForm(data.data);
      }
    });
    if (searchParams.get('status') !== 'Reject') {
      const currentCountry = countryValue;
      const isSanctionedCountry = sanctionedCountriesInfo.countries.includes(countryValue);
      if (isSanctionedCountry) {
        updateInitCountry(currentCountry);
        setValue('country', '');
      }

      veriffUserFetch();

      if (!isSanctionedCountry) {
        getCountryState(countryValue).then((res) => {
          if (res.status === 200) {
            const noStateOption = [{ value: 'N/A', label: 'N/A' }];
            const resState = res.data.options.length === 0 ? noStateOption : res.data.options;
            setLivingState(resState);
          }
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } else {
      getCountryState(countryValue).then((res) => {
        if (res.status === 200) {
          const noStateOption = [{ value: 'N/A', label: 'N/A' }];
          const resState = res.data.options.length === 0 ? noStateOption : res.data.options;
          setLivingState(resState);
        }
      });
      setIsLoading(false);
    }
  }, []);

  const renderInputCell = (item: InputInfoProps, index: number): JSX.Element => {
    switch (item.type) {
      case 'input':
        return (
          <StyledInputWrap key={item.id} isHalfWidth={item.id === 'zip_code'}>
            <Controller
              control={control}
              name={item.id}
              {...item.rules}
              rules={{
                ...item.rules,
                pattern: item?.rules?.pattern && {
                  value: new RegExp(item.rules?.pattern.value),
                  message: item.rules?.pattern.message
                }
              }}
              render={({
                field: {
                  onChange,
                  value
                }
              }) => (
                <Input
                  label={item.name}
                  height="48px"
                  placeholder={item.placeHolder}
                  onChange={(val) => {
                    onChange(val);
                    inputChangeHandler(item.id, index);
                  }}
                  value={watch(item.id) || ''}
                  errorMsg={errors[item.id] && (errors[item.id]?.message as unknown as string)}
                  warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, item.id, index)}
                  disabled={checkFieldIsWarning(rejectWarningFields, currentStep, item.id, index)}
                  markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, item.id, value, index)}
                  id={item.id}
                />
              )}
            />
          </StyledInputWrap>
        );
      case 'select':
        return (
          <StyledInputWrap key={item.id} isHalfWidth={item.id === 'state'}>
            <Controller
              control={control}
              name={item.id}
              {...item.rules}
              rules={{ required: item.rules?.required?.value }}
              render={({
                field: {
                  onChange,
                  value
                }
              }) => (
                <Select
                  label={item.name}
                  options={item.id === 'state' && livingState ? livingState : item.options}
                  inputHeight="48px"
                  onChange={(val) => {
                    if (item.id === 'country') {
                      selectHandler({ onChange, next: val, prev: value });
                    } else {
                      onChange(val);
                    }
                    inputChangeHandler(item.id, index);
                  }}
                  menuPlacement={item.id === 'state' || item.id === 'nationality' ? 'top' : 'bottom'}
                  currentValue={watch(item.id) || ''}
                  defaultValue={watch(item.id) || ''}
                  placeholder={item.placeHolder}
                  errorMsg={errors[item.id] && (errors[item.id]?.message as unknown as string)}
                  warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, item.id, index)}
                  disabled={checkFieldIsWarning(rejectWarningFields, currentStep, item.id, index)}
                  markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, item.id, value, index)}
                  id={item.id}
                />
              )}
            />
          </StyledInputWrap>
        );
      case 'phone':
        return (
          <StyledInputWrap key={item.id}>
            <Controller
              control={control}
              name={item.id}
              {...item.rules}
              rules={{ required: item.rules?.required?.value }}
              render={({
                field: {
                  onChange, value
                }
              }) => (
                <PhoneInput
                  id={item.id}
                  label={item.name}
                  height="48px"
                  onChange={(phoneVal: string, info: { [key: string]: string }) => {
                    onChange(phoneVal);
                    if (phoneVal === info.dialCode) {
                      setValue('mobile', null);
                      setPhoneValue(info.dialCode);
                    }
                    inputChangeHandler(item.id, index);
                  }}
                  defaultValue={value || phoneValue}
                  warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, item.id, index)}
                  disabled={checkFieldIsWarning(rejectWarningFields, currentStep, item.id, index)}
                  markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, item.id, value, index)}
                  localization={langData[i18n.language as keyof typeof langData]}
                />
              )}
            />
          </StyledInputWrap>
        );

      case 'date':
        return (
          <StyledInputWrap key={item.id}>
            <Controller
              control={control}
              name={item.id}
              {...item.rules}
              rules={{ required: item.rules?.required?.value }}
              render={({
                field: {
                  onChange,
                  value
                }
              }) => (
                <DatePicker
                  disableFuture
                  id={item.id}
                  label={item.name}
                  onChange={(val) => {
                    onChange(val);
                    inputChangeHandler(item.id, index);
                  }}
                  defaultValue={watch(item.id) || ''}
                  defaultCalendarMonth={appDayjs().subtract(18, 'year')}
                  maxDate={appDayjs().subtract(18, 'year')}
                  minDate={isAUS ? appDayjs().subtract(85, 'year') : undefined}
                  warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, item.id, index)}
                  disabled={checkFieldIsWarning(rejectWarningFields, currentStep, item.id, index)}
                  markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, item.id, value, index)}
                />
              )}
            />
          </StyledInputWrap>
        );

      default:
        return (
          <StyledInputWrap key={item.id}>
            <Input
              id={item.id}
              label={item.name}
              height="48px"
              placeholder={item.placeHolder}
              warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, item.id, index)}
              disabled={checkFieldIsWarning(rejectWarningFields, currentStep, item.id, index)}
            />
          </StyledInputWrap>
        );
    }
  };

  // const VerifyPopupContent = (
  //   <StyledPopupWrap>
  //     <StyledPopupContent>{t('provideIdForProtection')}</StyledPopupContent>
  //     <StyledPopupContent>{t('haveIdReadyAndClickStart')}</StyledPopupContent>
  //     <StyledHintWrap>
  //       {t('cameraOrWebcamRequired')}
  //     </StyledHintWrap>
  //   </StyledPopupWrap>
  // );

  // const RetryPopupContent = (
  //   <StyledPopupWrap>
  //     <StyledPopupContent>{t('retryPopupContent')}</StyledPopupContent>
  //   </StyledPopupWrap>
  // );

  const SanctionedPopupContent = (
    <StyledPopupWrap aria-label="Pop Up Content">
      <StyledPopupContent dangerouslySetInnerHTML={{ __html: t('compareCountry', { preCountry, curCountry }) }} />
      <StyledPopupContent dangerouslySetInnerHTML={{ __html: t('sanctionedCountryWarning', { preCountry, curCountry }) }} />
    </StyledPopupWrap>
  );

  const RegulationPopupContent = (
    <StyledPopupWrap aria-label="Pop Up Content">
      <StyledPopupContent dangerouslySetInnerHTML={{ __html: t('regulationChanged', { preCountry, curCountry }) }} />
      <StyledPopupContent dangerouslySetInnerHTML={{ __html: t('regulationChangedWarning', { preCountry, curCountry, regulation }) }} />
    </StyledPopupWrap>
  );

  const VeriffLoadingContent = (
    <StyledVeriffLoadingWrap>
      <CircularProgress />
      <StyledVeriffLoadingContent>
        {t('veriffLoadingContent')}
      </StyledVeriffLoadingContent>

    </StyledVeriffLoadingWrap>
  );

  return (
    <FormPanel title={t('Personal Information')}>
      <StyledContentWrap className="StyledContentWrap flx-row" gap={24}>
        {personalForm && !isLoading && (
          <>
            <StyledHalfContentWrap>
              {
                personalForm.map((item: InputInfoProps, index: number) => {
                  if (num && num > index) {
                    return renderInputCell(item, 0);
                  }
                  return null;
                })
              }
            </StyledHalfContentWrap>
            <StyledHalfContentWrap>
              {
                personalForm.map((item: InputInfoProps, index: number) => {
                  if (num && num <= index) {
                    return renderInputCell(item, 0);
                  }
                  return null;
                })
              }
            </StyledHalfContentWrap>
          </>
        ) }
        {
          isLoading && (
          <StyledLoadingWrap>
            <CircularProgress className="custom-progress" />
          </StyledLoadingWrap>
          )
        }
      </StyledContentWrap>
      <CustomPopup
        title={t('confirmRegulation')}
        contents={RegulationPopupContent}
        onConfirm={() => {
          setOpenModal(false);
          setChangeRegulation(true);
          if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach((fieldKey) => {
              resetField(fieldKey);
            });
          }
          ['funding_currency', 'leverage', 'mt_account_type', 'state', 'zip_code'].forEach((el: string) => setValue(el, null));

          ErrorHandler(updateCountry({
            accountID: localStorage.getItem('accountID')
              ? localStorage.getItem('accountID') : null,
            form: {
              ...getValues(),
              original_country: originCountryRef.current,
              current_step: CreateAccountSteps.PersonalInformation,
              form_step: CreateAccountSteps.TradingAndEmployment
            }
          }), dispatch).then((res) => {
            if (res && res.status === 200) {
              updateShowTip(true);
              updatePOACertification(isAUS && !localStorage.getItem('hasVeriffAddress'));
              const curFlow = isAUS ? 'ASIC' : 'VFSC';
              const curMethod = res.data.jumio_verified === '0' ? 'Traditional' : 'AI';
              updateFlow(curFlow);
              updateMethod(curMethod);

              if (curFlow === 'VFSC') {
                updateSteps(PERSONAL_ACCOUNT_STEPS.AIVFSC);
              } else if (res.data.require_poa) {
                updateSteps(PERSONAL_ACCOUNT_STEPS.AIASICPOA);
              } else {
                updateSteps(PERSONAL_ACCOUNT_STEPS.AIASIC);
              }

              Object.keys(res.data).forEach((eachKey) => {
                if (['_method'].some((el: string) => el !== eachKey)) {
                  setValue(eachKey, res.data[eachKey]);
                }
                if (eachKey === 'terms_and_conditions') {
                  const tncObj = JSON.parse(String(res.data.terms_and_conditions));
                  if (tncObj !== undefined && tncObj !== null) {
                    Object.keys(tncObj).forEach((eachTerm) => {
                      setValue(eachTerm, tncObj[eachTerm]);
                    });
                  }
                }
              });
              setValue('account_type', 'personal');
              if (curFlow === 'VFSC') {
                dispatch(backTwoSteps());
              } else {
                dispatch(backStep());
              }
            }
          }).catch(() => {
            setValue('country', originCountryRef.current);
          });
        }}
        openModal={openModal}
        onClose={() => {
          // After using veriff to update the country, the state is updated at the same time (line 215-225)
          // but when the user click cancel, we need to restore state at the same time
          getCountryState(originCountryRef.current).then((res) => {
            if (res.status === 200) {
              const noStateOption = [{ value: 'N/A', label: 'N/A' }];
              const resState = res.data.options.length === 0 ? noStateOption : res.data.options;
              setLivingState(resState);
            }
          });
          setValue('country', originCountryRef.current);
          setOpenModal(false);
        }}
      />
      <CustomPopup
        title={t('confirmRegulation')}
        contents={SanctionedPopupContent}
        onClose={() => setOpenSanctionedCountriesModal(false)}
        openModal={openSanctionedCountriesModal}
      >
        <Button aria-label="Pop Up OK" onClick={() => setOpenSanctionedCountriesModal(false)}>
          {t('ok')}
        </Button>
      </CustomPopup>

      {/* <CustomPopup
        title={t('verifyYourIdentity')}
        contents={VerifyPopupContent}
        openModal={popupType === PopUpType.VerifyIdentity}
      >
        <Button aria-label="Pop Up OK" onClick={handleStart}>
          {t('start')}
        </Button>
      </CustomPopup> */}
      {/* Change three attempts of Jumio to 1 attempt */}
      {/* <CustomPopup
        title={t('retryPopupTitle')}
        contents={RetryPopupContent}
        openModal={popupType === PopUpType.Retry}
      >
        <Button aria-label="Pop Up OK" onClick={handleStart}>
          {t('tryAgain')}
        </Button>
      </CustomPopup> */}
      <BackdropSpinner open={changeRegulation || veriffFetch} content={veriffFetch ? VeriffLoadingContent : undefined} />
    </FormPanel>
  );
};

export default PersonDetails;
